#home-div {
  display: block;
  width: 75%;
}

#intro-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

#profile-pic {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 50px;
  border: 1px solid var(--accent);
  box-shadow: 0px 0px 5px lightgrey;
}

#icons {
  display: flex;
  flex-direction: row;
}

.icon {
  color: var(--hoverColor);
  font-size: 25px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-top: 10px;
}

.icon svg {
  margin-top: -12.5px;
  width: 48px;
  height: 48px;
  fill: var(--accent);
}

.icon path {
  fill: var(--accent);
}

.ai-icon {
  font-size: 35px;
  margin-top: 7px;
}

.icon:hover {
  cursor: pointer;
}

.ai-cv {
  margin-top: -1.5px;
}

@media only screen and (max-width: 1200px) {
  #home-div {
    width: 100%;
  }
 }

 @media only screen and (max-width: 1020px) {
  #profile-pic {
    width: 250px;
    height: 250px;
  }
  .icon {
    font-size: 20px;
  }
  .ai-icon {
    font-size: 28px;
  }
  .icon svg {
    width: 38px;
    height: 38px;
    margin-top: -10px;
  }
 }

 @media only screen and (max-width: 780px) {
  #intro-div {
    flex-direction: column-reverse;
    align-items: start;
  }
  #profile-pic {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 200px;
    height: 200px;
  }
 }

 @media only screen and (max-width: 610px) {
  #intro-div {
    align-items: center;
  }
  #icons {
    justify-content: center;
  }
  .icon {
    margin-right: 12.5px;
    margin-left: 12.5px;
  }
  #text-and-icons {
    display: flex;
    flex-direction: column-reverse;
  }
 }

 @media only screen and (max-width: 300px) {
  #text-and-icons {
    width: 250px;
  }
  .icon {
    margin-right: 10px;
    margin-left: 10px;
  }
 }