#news-div {
  display: block;
  width: 75%;
}

.news-item-box {
  width: 200px;
  height: 300px;
  padding: 20px;
  margin: 10px;
  border: 1px solid var(--accent);
  box-shadow: 0px 0px 5px lightgrey;
}

.news-item-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: 50% 50%;
  box-shadow: 0px 0px 5px whitesmoke;
}

#news-items-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -10px;
}

.news-item-date {
  font-size: 0.8em;
  margin-top: 8px;
  margin-bottom: -8px;
  font-weight: bold;
}

.news-item-text {
  font-size: 0.8em;
}

@media only screen and (max-width: 1200px) {
  #news-div {
    width: 100%;
  }
 }

 @media only screen and (max-width: 1020px) {
  .news-item-box {
    width: 150px;
    height: 225px;
    margin: 7.5px;
  }
  #news-items-div {
    margin-left: -7.5px;
  }
  .news-item-image {
    width: 150px;
    height: 150px;
  }
  .news-item-date {
    font-size: 0.7em !important;
    margin-top: 6px;
    margin-bottom: -6px;
  }
  .news-item-text {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 610px) {
  #news-items-div {
    justify-content: center;
    margin: 0;
  }
  .news-item-box {
    width: 200px;
    height: 300px;
    margin: 10px;
  }
  
  .news-item-image {
    width: 200px;
    height: 200px;
  }
  
  .news-item-date {
    font-size: 0.8em;
    margin-top: 8px;
    margin-bottom: -8px;
  }
  
  .news-item-text {
    font-size: 0.8em;
  }
}
